/* General Reset */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: black;
  color: white;
  overflow-x: hidden;
  text-align: center;
}

/* Profile Section */
.profile-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.profile-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid white;
  object-fit: cover;
}



/* Static Text */
.static-text {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  line-height: 1.5;
  margin: 0 auto;
}

/* Highlight Word */
.highlight-word {
  color: pink;
  font-weight: bold;
}

/* PhysicsSquares Canvas (Ensures It Renders Behind Text) */
.physics-squares {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Below text */
  pointer-events: none; /* Prevents blocking clicks */
}

/* Contact Form */
.ContactForm {
  width: 90%;
  max-width: 600px;
  padding: 20px;
  background-color: black;
  border: 1px solid #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  box-sizing: border-box;
}

/* Input Fields (Consistent Width & Height) */
.ContactForm input,
.ContactForm textarea {
  width: 100%;
  max-width: 500px; /* Ensuring a consistent width */
  height: 45px; /* Consistent height for all inputs */
  padding: 12px;
  border: 1px solid #555;
  border-radius: 3px;
  background: #222;
  color: white;
  text-align: center;
  box-sizing: border-box;
}

/* Textarea Specific Styles */
.ContactForm textarea {
  height: 120px; /* Fixed height */
  resize: none; /* Prevents resizing */
}

/* Submit Button */
.ContactForm button {
  width: 100%;
  max-width: 500px;
  background-color: #444;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.3s ease-in-out;
}

.ContactForm button:hover {
  background-color: #555;
}

/* Footer */
.footer {
  width: 100%;
  padding: 15px;
  text-align: center;
  background-color: black;
  color: white;
  opacity: 50%;
  position: relative;
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .static-text {
    font-size: 1.5rem;
  }

  .physics-container {
    min-height: 180px;
  }

  .ContactForm input,
  .ContactForm textarea,
  .ContactForm button {
    max-width: 90%;
  }
}

@media screen and (max-width: 480px) {
  .static-text {
    font-size: 1.2rem;
  }



}
.ContactForm input,
.ContactForm textarea,
.ContactForm button {
  max-width: 100%;
}

/* PhysicsSquares Container (Locks to Text Size) */
.physics-container {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column; /* Ensures text & squares align */
  justify-content: center;
  align-items: center;
  background: black;
  position: relative;
  margin: 20px auto;
  overflow: hidden;
  border: 2px solid white;
  box-sizing: border-box;
  padding: 20px;
}

/* Overlay Text (Ensures Container Expands) */
.overlay-text {
  width: 80%;
  max-width: 600px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  z-index: 2; /* Ensures text appears above squares */
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent for readability */
  border-radius: 10px;
  position: relative; /* Prevents overlapping */
}

/* PhysicsSquares Canvas (Now Respects Text Container) */
.physics-squares {
  width: 100%;
  height: 100%;
  position: absolute; /* Locks it inside `.physics-container` */
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}
